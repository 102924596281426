import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
	desc?: string;
	descId?: string;
	className?: string;
}
const ChevronCircleTick = ({
	title,
	titleId,
	desc,
	descId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		className={props.className || ''}
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="16" height="16" rx="8" fill="#70B030" />
		<path d="M4 7.34211L7.09677 10.5L12 5.5" stroke="white" />
	</svg>
);
export default ChevronCircleTick;
